import React from "react";
import Icon from "@ant-design/icons";

export const PhoneOutlinedSvg = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.52271 1C4.10367 1 2.03962 2.52314 1.5236 3.53857C0.786112 4.98982 0.907831 5.55996 1.3946 7.09256C2.03962 9.12341 3.93075 12.7232 6.55473 15.216C10.2958 18.77 13.7789 20.2931 15.327 20.8008C16.875 21.3085 18.552 20.8008 19.5841 19.7854C20.6161 18.77 21.6481 17.7545 20.4871 16.2314C19.6644 15.1521 18.4659 13.9043 17.004 13.1851C15.6748 12.5312 14.8826 12.7692 14.4239 13.6928C14.1704 14.2034 14.0916 15.1814 13.9079 15.7237C13.6762 16.4078 12.7469 16.2314 11.7149 15.7237C10.7268 15.2376 8.23178 13.1851 6.16772 10.1388C4.8889 8.25146 6.93298 8.23923 8.23178 7.60027C9.2638 7.09256 9.58372 5.9248 8.74779 4.55399C7.19975 2.01543 6.68374 1 5.52271 1Z" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

const PhoneOutlined = (props: any) => (
  <Icon component={PhoneOutlinedSvg} {...props} />
);

export default PhoneOutlined;
