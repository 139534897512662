import React from "react";
import Icon from "@ant-design/icons";

const PlusSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.53558 1.57227H7.46415C7.36891 1.57227 7.32129 1.61988 7.32129 1.71512V7.32227H2.00028C1.90504 7.32227 1.85742 7.36988 1.85742 7.46512V8.53655C1.85742 8.63179 1.90504 8.67941 2.00028 8.67941H7.32129V14.2866C7.32129 14.3818 7.36891 14.4294 7.46415 14.4294H8.53558C8.63081 14.4294 8.67843 14.3818 8.67843 14.2866V8.67941H14.0003C14.0955 8.67941 14.1431 8.63179 14.1431 8.53655V7.46512C14.1431 7.36988 14.0955 7.32227 14.0003 7.32227H8.67843V1.71512C8.67843 1.61988 8.63081 1.57227 8.53558 1.57227Z"
      fill="white"
    />
  </svg>
);

const PlusIcon = (props: any) => <Icon component={PlusSVG} {...props} />;

export default PlusIcon;
