import React from "react";
import Icon from "@ant-design/icons";

const PinOutlineSvg = () => (
  <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 21C9.5 21 1 15 1 9C1 4 5.25 1 9.5 1C13.75 1 18 4 18 9C18 15 9.5 21 9.5 21Z" stroke="currentColor" strokeWidth="2"/> 
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 11C10.6737 11 11.625 10.1047 11.625 9C11.625 7.89533 10.6737 7 9.5 7C8.32629 7 7.375 7.89533 7.375 9C7.375 10.1047 8.32629 11 9.5 11V11Z" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

const PinOutline = (props: any) => (
  <Icon component={PinOutlineSvg} {...props} />
);

export default PinOutline;
